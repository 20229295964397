$bgNavy: #1d2a3ca1;
$dark-navy: #020c1b;
$navy: #0a192f;
$light-navy: #112240;
$lightest-navy: #233554;
$navy-shadow: rgba(2,12,27,0.7);
$dark-slate: #495670;
$slate: #8892b0;
$light-slate: #a8b2d1;
$lightest-slate: #ccd6f6;
$green: #64ffda;
