@import 'src/styles/colors.scss';

.education-container {
  box-sizing: border-box;
  padding: 0 20px;
  margin: 50px 0;
  border-left: solid 5px $lightest-slate;
}

.education-degree {
  color: $green;
}

.education-school {
  font-weight: bold;
}