@import 'src/styles/colors.scss';

.nav-container{
  display: flex;
  padding: 0px 50px;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  color: $lightest-slate;
}

.nav {
  display: none;
}

.nav-banner-container {
  position: relative;
}

.nav-banner-lightbox {
  position: relative;
  height: calc(100vh - 100px);
  background: $bgNavy;
  transition: background 0.2s ease-in-out;
  &.hide {
    background: unset;
    display: none;
  }
}

.nav-banner {
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  top: 0;
  height: calc(100vh - 100px);
  background-color: $navy;
  width: 300px;
  right: -300px;
  transition: right 0.3s ease-in-out;

  &.show {
    right: 0;
  }
}

.nav-banner-content {
  box-sizing: border-box;
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: inherit;
  gap: 15px;

  & .nav-link {
    padding-bottom: 5px;
  }
}

.nav-burger {
  display: flex;
}

.nav-logo-container {
  display: flex;
  gap: 20px;
}

.nav-logo-socials {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.nav-logo {
  font-weight: bold;
  box-sizing: border-box;
  padding: 10px 15px;
  line-height: 20px;
  border: dotted;
  border-color: $green;
}

.nav-link {
  box-sizing: border-box;
  margin: 0 5px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  border-bottom: none;
  color: $lightest-slate;
  transition: color 0.5s ease-in-out;
  padding-bottom: 10px;

  &:hover {
    color: $green;
  }
  &.active {
    color: $green;
    border-bottom: solid 2px $green;
  }

  .text-selected {
    border-bottom: solid;
    color: $green;
  }
}

@media (min-width: 768px) {
  .nav {
    display: flex;
    gap: 20;
  }
  .nav-burger {
    display: none;
  }
  .nav-banner-lightbox {
    display: none;
  }
}