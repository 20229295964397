@import 'src/styles/colors.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  height: 100px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  background-color: $navy;
}
.app-footer {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    padding-left: 5px;
    text-decoration: none;
    color: $lightest-slate;
    font-weight: bold;
  }
}

.App-link {
  color: #61dafb;
}

section {
  padding: 100px 50px 0 50px;
  text-align: left;
  & p {
    max-width: 1200px;
  }
}

.section-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.App-profile {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
}

.home-title {
  font-size: 5rem;
}

.home-summary {
  font-size: 1.1rem;
  line-height: 1.6rem;
}

.section-title {
  font-size: 3rem;
}

@media (min-width: 600px) {
  .section-title {
    font-size: 4rem;
  }

  .App-profile {
    height: calc(100vh - 100px);
  }
}
